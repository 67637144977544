<template>
  <b-card>
    <b-table
      ref="table"
      :no-provider-sorting="true"
      :items="getRegisteredMembers"
      :fields="tableColumns"
      class="position-relative"
      responsive
      empty-text="No registered members in this session"
      show-empty
    >
      <template #cell(user.profile.birthday)="{item}">
        {{ item.user && item.user.profile.birthday ?
          `${$moment().diff(item.user.profile.birthday, 'years')} Years`
          : '' }}
      </template>
      <template #cell(user.profile.vaccinated)="{item}">
        {{ item.user ? (item.user.profile.vaccinated ? 'Yes' : 'No') : '' }}
      </template>
      <template #cell(created_at)="{item}">
        {{ $moment(item.created_at).format("MMM DD dddd YYYY") }}
      </template>
    </b-table>
  </b-card>
</template>
<script>
export default {
  name: 'ViewSession',
  data() {
    return {
      tableColumns: [
        { key: 'id' },
        { key: 'attendee_name', label: 'name' },
        { key: 'booking_id', label: 'booking ID' },
        { key: 'created_at', label: 'booking date' },
        { key: 'user.email', label: 'email' },
        { key: 'user.phone_number', label: 'phone' },
        { key: 'user.profile.gender', label: 'gender' },
        { key: 'user.profile.birthday', label: 'age' },
        { key: 'user.profile.vaccinated', label: 'vaccinated' },
        { key: 'attending_status', label: 'booking status' },
      ],
    }
  },
  methods: {
    getRegisteredMembers() {
      const promise = this.$activities.get(`internalops/session/session_details/${this.$router.currentRoute.params.id}`)

      return promise.then(res => {
        const registeredMembers = res.data.data.attendess.flat()
        return registeredMembers || []
      })
    },
  },
}
</script>
<style lang="">

</style>
